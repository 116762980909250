<template>
  <div class="main-card">
    <v-divider class="pa-0 ma-0"/>
    <div>
      <v-progress-linear
          class="ma-0"
          color="primary"
          indeterminate
          v-if="this.loading"
      ></v-progress-linear>
    </div>
    <v-form ref="form" v-model="valide">
      <div class="card-body">

        <v-layout>
          <v-flex md12 mt-5 mr-2 ml-5>
            {{ $t('settings.lang_enterImapEmailServerSettings') }}<br><br>
          </v-flex>
        </v-layout>

        <v-row>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsOutServer')"
                              :rules="[rules.required, rules.emailServer]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="mailHost"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSetsPort')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              max="64000"
                              min="0"
                              outlined
                              required
                              type="number"
                              v-model="mailPort"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select :disabled="this.loading" :items="encryptions" :label="$t('settings.lang_mailSettingsEncryption')"
                          dense
                          item-text="name"
                          item-value="value"
                          outlined
                          v-model="encryption"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsUsername')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="userName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSettingsPassword')"
                              :rules="[rules.required,rules.min]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              type="password"
                              v-model="mailPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                              :label="$t('settings.lang_mailSetsSenderName')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="senderName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" :label="$t('settings.lang_mailSetsEmail')"
                              :rules="[rules.email,rules.required]"
                              @focus="showTouchKeyboard"

                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="senderMail"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-divider class="ma-0 pa-0"/>
      <div class="card-footer transparent text">
        <v-row justify="end" no-gutters>
          <v-btn :disabled="this.loading||!valide" @click="mailServerTest()" color="info" large>
            {{$t('settings.lang_mailTest')}}
          </v-btn>
          <v-btn :disabled="this.loading||!valide" @click="update()" color="success" large>
            {{$t('generic.lang_apply')}}
          </v-btn>
        </v-row>
      </div>
    </v-form>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";



export default {
  name: "VofficeEmailSettingsComponent",
  components: {},
  mixins: [mixin],
  data() {
    return {
      valide: false,
      loading: true,
      rules: {
        email: (email) => {
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email) || this.$t('generic.lang_inputShouldBeValideEmail');
        },
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t('generic.lang_requiredField'))
        },
        min: (field) => {
          return ((field && field.toString().length >= 6) || this.$t('generic.lang_passwordShouldBeAtLeast6CharsMinimum'))
        },
        emailServer: (value) => {
          const pattern = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$/gm;
          
          return pattern.test(value) || this.$t('generic.lang_invalidEmailServer');
        },
      },
      encryptions: [
        {name: this.$t('generic.lang_gen_none'), value: 0},
        {name: 'SSL', value: 1},
        {name: 'TLS', value: 2},
      ],
      mailHost: null,
      mailPort: null,
      encryption: 0,
      userName: null,
      mailPassword: null,
      senderMail: null,
      senderName: null,
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.GET)
          .then(res => {
            if (res.data.success) {
              this.encryption = parseInt(res.data.settings.mailEncryption)
              this.mailHost = res.data.settings.mailHost
              this.mailPort = parseInt(res.data.settings.mailPort)
              this.senderName = res.data.settings.mailSenderName
              this.senderMail = res.data.settings.mailSendereMailAddress
              this.userName = res.data.settings.mailUsername
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.loading = false;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.UPDATE, {
        mailEncryption: this.encryption,
        mailHost: this.mailHost,
        mailPort: this.mailPort,
        mailSenderName: this.senderName,
        mailSendereMailAddress: this.senderMail,
        mailUsername: this.userName,
        mailPassword: this.mailPassword,
      }).then(res => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    },
    mailServerTest() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.EMAILTEST, {
      }).then(res => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      })
    }

  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>